/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-06-14",
    versionChannel: "nightly",
    buildDate: "2023-06-14T00:19:12.105Z",
    commitHash: "128416cccdc34dd7d0b01d35e1f91336a5724423",
};
